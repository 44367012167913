import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'


/**
 * fonts
 */

const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/5.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace

const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        const textGeometry = new TextGeometry(
            '((((((((((((((  ))))))))))))))',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
                
            }
        )
        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture})
        

        for(let i = 0; i < 300; i++)
        {
            const clock = new THREE.Clock()
            const donut = new THREE.Mesh(donutGeometry, material)
            
            const elapsedTime = clock.getElapsedTime()

            donut.position.set((Math.random()-0.5)*10, (Math.random()-0.5)*10, (Math.random()-0.5)*10)
            donut.rotation.set((Math.random()-0.5)*10,(Math.random()-0.5)*10,(Math.random()-0.5)*10)
            scene.add(donut)

        }

        
   
        textGeometry.computeBoundingBox()
        console.log(textGeometry.boundingBox)
        textGeometry.center()
        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)
    } 
)

//


/**
 * lights
 * 
 */



/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
//const textureLoader = new THREE.TextureLoader()

/**
 * Object
 */
const cube = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    new THREE.MeshBasicMaterial()
)

// scene.add(cube)
const ambientLight = new THREE.AmbientLight()
scene.add(ambientLight)

const pointLight = new THREE.PointLight()
scene.add(pointLight)

gui
    .add(pointLight.position, 'y')
    .min(-5)
    .max(5)
    .step(.001)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */


const mouse = {
    x:0,
    y:0
}

window.addEventListener('mousemove', (event) =>
{
    mouse.x = (event.clientX / sizes.width) - 0.5
    mouse.y = (event.clientY / sizes.width) - 0.5

    
    //console.log(mouse.x, mouse.y)
})

const click = {
    x:0,
    y:0
}

window.addEventListener('mousedown', (event) =>
{
    click.x = event.clientX/event.clientX;
    click.y = event.clientY/event.clientX;

    console.log(click.x, click.y)
})

window.addEventListener('mouseup', (event) =>
{
    click.x = 0;
    click.y = 0;

    console.log(click.x, click.y)
})



const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    const test = new THREE.Vector2(click.x, click.y)

    
    const distance = test.length();
    const cameraPosition = camera.position.clone();

    // Update donut positions and rotations
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.geometry instanceof THREE.TorusGeometry) {
            child.rotation.x += 0.01;
            child.rotation.y += 0.01;
            child.rotation.z += 0.01;
            child.scale.x = Math.sin(elapsedTime/10);
            child.scale.y = Math.sin(elapsedTime/10);
            const factor = 50;
            const displacement = 0.01 - distance / factor;
            //const direction = new THREE.Vector3(mouse.x, mouse.y, 0).sub(cameraPosition).normalize();
            
            
            //const movementSpeed = 0.1;
            //const movement = direction.clone().multiplyScalar(movementSpeed);
            //child.position.add(movement);
            
            const direction = new THREE.Vector3().copy(child.position).normalize();
            child.position.add(direction.multiplyScalar(displacement));

            //child.position.x += distance
        }
    });

    //calcDonuts()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    click.x == 0
}

tick()